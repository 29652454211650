// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // live
  ApiUrl : 'https://ovt.occasionvenue.com/Apis/',
  imgUrl : 'https://ovt.occasionvenue.com/files/category_image/',
  imgBlogUrl : 'https://ovt.occasionvenue.com/files/blog_img/',
  vendorImgUrl : 'https://ovt.occasionvenue.com/files/dealer_logo/thumb/',
  vendorGalleryUrl : 'https://ovt.occasionvenue.com/files/dealer_gallery/',
  venueImgUrl : 'https://ovt.occasionvenue.com/files/venue_logo/thumb/',
  venueGalleryUrl : 'https://ovt.occasionvenue.com/files/venue_gallery/'

  /// local
  // ApiUrl : 'http://local.ov.com/Apis/',
  // imgUrl : 'http://local.ov.com/files/category_image/',
  // imgBlogUrl : 'http://local.ov.com/files/blog_img/',
  // vendorImgUrl : 'http://local.ov.com/files/dealer_logo/thumb/',
  // vendorGalleryUrl : 'http://local.ov.com/files/dealer_gallery/',
  // venueImgUrl : 'http://local.ov.com/files/venue_logo/thumb/',
  // venueGalleryUrl : 'http://local.ov.com/files/venue_gallery/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
