import { NavController } from '@ionic/angular';
import { ObjService } from './service/obj.service';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Storage } from '@ionic/storage';
import { UsersService } from './service/users.service';
import { environment } from '../environments/environment';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  userData: any;
  myState: any;
  stateList: any;
  cityDDList: any;

  venueList: any;
  venderList: any;

  userMobile: any;
  userPass: any;
  errorMsg: any;

  userNameReg: any;
  userEmailReg: any;
  userMobileReg: any;
  userPassReg: any;

  userOtp: any;

  openLogin: boolean = false;
  openVenue: boolean = false;
  openVender: boolean = false;
  openState: boolean = false;
  openCity: boolean = false;
  opnFilterVendor: boolean = false;
  opnFilterVenue: boolean = false;
  selectCountry: boolean = false;

  isForLogin: boolean=true;
  isForReg: boolean=false;
  isForOtp: boolean=false;


  imgPath = environment.imgUrl;


  venue: any = 'Select Venue';
  vender: any = 'Select Vender';
  city: any = 'Select City';

  constructor(private metaTagService: Meta, private objService: ObjService,
    private navController: NavController,
    private storage: Storage, private usersService: UsersService) {
    this.storage.create();



    this.objService.getOpenState().subscribe((data) => {
      this.openState = true;
    });

    this.objService.getOpenCity().subscribe((data) => {
      this.openCity = true;
    });

    this.objService.getOpenVender().subscribe((data) => {
      this.openVender = true;
    });
    this.objService.getOpenVenue().subscribe((data) => {
      this.openVenue = true;
    });

    this.objService.getForLogin().subscribe((data) => {
      this.openLogin = true;
    });

    this.objService.getFilterVendor().subscribe((data) => {
      this.opnFilterVendor = true;
    });

    this.objService.getFilterVenue().subscribe((data) => {
      this.opnFilterVenue = true;
    });

    this.objService.getForCountry().subscribe((data) => {
      this.selectCountry = true;
    });

  }


  async ngOnInit() {
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Angular SEO Integration, Music CRUD, Angular Universal' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Digamber Singh' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);

    this.getStateList();
    this.setVenderVenue();
    this.getBlogList();

    this.storage.get('userData').then((val) => {
      this.userData = val;
    });

    this.getMyState();
  }

  async setVenderVenue() {
    this.usersService.getVenderVenue({}).then(async (res: any) => {
      this.storage.set('venderList', res.vendor);
      this.storage.set('venueList', res.venue);
      this.venueList = res.venue;
      this.venderList = res.vendor;

      this.storage.set('capacity', res.capacity);
      this.storage.set('looking_for', res.looking_for);
      this.storage.set('price', res.price);
      this.storage.set('rating', res.rating);
    });
  }

  async getStateList() {
    this.usersService.getStates({ 'country_id': '1' }).then(async (res: any) => {
      this.storage.set('stateList', res);
      this.stateList = res;
    });
    this.usersService.getCity({ 'state_id': '1' }).then(async (res: any) => {
      this.storage.set('cityList', res);
      this.cityDDList = res;
    });
  }

  async getBlogList() {
    this.usersService.getBlog({}).then(async (res: any) => {
      this.storage.set('blogList', res);
    });
  }

  userLogout() {
    this.storage.remove('userData');
    this.userData = '';
  }

  getMyState() {
    this.storage.get('myState').then((val) => {
      this.myState = val;
      this.openState = !(val) ? true : false;
    });
  }


  setMyState(state) {
    this.openState = false;
    this.storage.set('myState', state);
    this.myState = state;
    this.objService.setStateData(state);
    this.getCity(state.id);
  }

  setMyCity(city) {
    this.openCity = false;
    this.storage.set('myCity', city);
    this.objService.setCityData(city);
  }

  getCity(state_id) {
    this.usersService.getCity({ 'state_id': state_id }).then(async (res: any) => {
      this.storage.set('cityList', res);
      this.cityDDList = res;
      this.objService.publishCityData(res);
    });
  }

  login() {

    if (!this.isEmpty(this.userMobile) && !this.isEmpty(this.userPass)) {
      $('#login_btn').html('Processing...');
      this.usersService.customerLogin({ 'mobile': this.userMobile, 'password': this.userPass }).then(async (res: any) => {
        $('#login_btn').html('Login');
        if (res.status == "success") {
          this.openLogin = false;
          this.userData = res.user;
          this.storage.set('userData', res.user);
        }else{
          this.errorMsg = "Please enter valid mobile no or password."
          setTimeout(() => {
            this.errorMsg = '';
          }, 6000);
        }
      });
    } else {
      this.errorMsg = "Please enter valid mobile no or password."
      setTimeout(() => {
        this.errorMsg = '';
      }, 6000);
    }
  }

  Registration() {
    if (!this.isEmpty(this.userNameReg) && !this.isEmpty(this.userEmailReg) && !this.isEmpty(this.userMobileReg) && !this.isEmpty(this.userPassReg)) {
      this.usersService.customerSignup({ name:this.userNameReg,email:this.userEmailReg,'mobile': this.userMobileReg, 'password': this.userPassReg }).then(async (res: any) => {
        console.log(res);
        if (res.status == "success") {
          this.openLogin = false;
          this.userData = res.user;
          this.storage.set('userData', res.user);
        }else{

        }
      });
    } else {
      this.errorMsg = "All fields are required."
      setTimeout(() => {
        this.errorMsg = '';
      }, 6000);
    }
  }

  checkOtp() {
    if (!this.isEmpty(this.userMobile) && !this.isEmpty(this.userPass)) {
      this.usersService.customerSignup({ name:this.userNameReg,email:this.userEmailReg,'mobile': this.userMobileReg, 'password': this.userPassReg }).then(async (res: any) => {
        console.log(res);
        if (res.status == "success") {
          this.openLogin = false;
          this.userData = res.user;
          this.storage.set('userData', res.user);
        }
      });
    } else {
      this.errorMsg = "Please enter valid mobile no or password."
      setTimeout(() => {
        this.errorMsg = '';
      }, 6000);
    }
  }

  openLoginPop() {
    this.closeAllPopup();
    this.openLogin = true;
  }
  closeLogin() {
    this.openLogin = false;
  }

  openCountry() {
    this.closeAllPopup();
    this.selectCountry = true;
  }
  closeCountry() {
    this.selectCountry = false;
  }

  openVenuePop() {
    this.closeAllPopup();
    this.openVenue = !this.openVenue;
  }
  closeVenuePop() {
    this.openVenue = false;
  }

  openVenderPop() {
    this.closeAllPopup();
    this.openVender = !this.openVender;
  }

  closeVenderPop() {
    this.openVender = false;
  }


  openFilterVendor() {
    this.closeAllPopup();
    this.opnFilterVendor = !this.opnFilterVendor;
  }

  closeFilterVendor() {
    this.opnFilterVendor = false;
  }

  openFilterVenue() {
    this.closeAllPopup();
    this.opnFilterVenue = !this.opnFilterVenue;
  }

  closeFilterVenue() {
    this.opnFilterVenue = false;
  }



  openStatePop() {
    this.closeAllPopup();
    this.openState = !this.openState;
  }

  closeStatePop() {
    this.openState = !this.openState;
  }

  openCityPop() {
    this.closeAllPopup();
    this.openCity = !this.openCity;
  }

  closeCityPop() {
    this.openCity = !this.openCity;
  }


  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }


  selectVender(name) {
    this.vender = name;
  }
  selectVenue(name) {
    this.venue = name;
  }

  selectCity(name) {
    this.city = name;
  }


  goToVender() {
    this.opnFilterVendor = false;
    let catName = this.vender.toLowerCase();
    let cityName = this.city.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
    this.navController.navigateForward("/best-vendor/" + catName + "/" + cityName);
  }

  goToVenue() {
    this.opnFilterVenue = false;
    let catName = this.venue.toLowerCase()
    let cityName = this.city.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
    this.navController.navigateForward("/best-venue/wedding/" + catName + "/" + cityName);
  }

  closeAllPopup(){
    this.openLogin= false;
    this.openVenue= false;
    this.openVender = false;
    this.openState= false;
    this.opnFilterVendor= false;
    this.opnFilterVenue= false;
  }

  forLogin(){
    this.isForLogin=true;
    this.isForReg=false;
    this.isForOtp=false;
  }

  forRegister(){
    this.isForLogin=false;
    this.isForReg=true;
    this.isForOtp=false;
  }

  forOtp(){
    this.isForLogin=false;
    this.isForReg=false;
    this.isForOtp=true;
  }

}
