import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ObjService {

    private cityList = new Subject<any>();
    private myState = new Subject<any>();
    private myCity = new Subject<any>();
    private forUserLogin = new Subject<any>();
    private forOpenState = new Subject<any>();
    private forOpenCity = new Subject<any>();
    private forOpenVender = new Subject<any>();
    private forOpenVenue = new Subject<any>();

    private forUserCountry = new Subject<any>();
    private forFilterVendor = new Subject<any>();
    private forFilterVenue = new Subject<any>();

    publishCityData(data: any) {
        this.cityList.next(data);
    }

    getCityObservable(): Subject<any> {
        return this.cityList;
    }

    setStateData(data: any) {
        this.myState.next(data);
    }

    getStateData(): Subject<any> {
        return this.myState;
    }

    setCityData(data: any) {
        this.myCity.next(data);
    }

    getCityData(): Subject<any> {
        return this.myCity;
    }

    setOpenState(data: any) {
        this.forOpenState.next(data);
    }

    getOpenState(): Subject<any> {
        return this.forOpenState;
    }

    setOpenCity(data: any) {
        this.forOpenCity.next(data);
    }

    getOpenCity(): Subject<any> {
        return this.forOpenCity;
    }



    setOpenVender(data: any) {
        this.forOpenVender.next(data);
    }

    getOpenVender(): Subject<any> {
        return this.forOpenVender;
    }

    setOpenVenue(data: any) {
        this.forOpenVenue.next(data);
    }

    getOpenVenue(): Subject<any> {
        return this.forOpenVenue;
    }




    setForLogin(data: any) {
        this.forUserLogin.next(data);
    }

    getForLogin(): Subject<any> {
        return this.forUserLogin;
    }

    setForCountry(data: any) {
        this.forUserCountry.next(data);
    }

    getForCountry(): Subject<any> {
        return this.forUserCountry;
    }

    setFilterVendor(data: any) {
        this.forFilterVendor.next(data);
    }

    getFilterVendor(): Subject<any> {
        return this.forFilterVendor;
    }

    setFilterVenue(data: any) {
        this.forFilterVenue.next(data);
    }

    getFilterVenue(): Subject<any> {
        return this.forFilterVenue;
    }



}
